import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Grid,
  OutlinedInput,
  Link,
} from "@mui/material";
import {
  MoreVert,
  PictureAsPdf,
  Description,
  Person,
  Close,
  CloudUpload,
} from "@mui/icons-material";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PateintDocumentViewer from "./PateintDocumentViewer";
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from './SnackBar';
import LoadingSpinner from './LoadingSpinner'; 
import ConfirmationDialog from './ConfirmationDialog';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { saveAs } from 'file-saver';


const DocumentList = ({ token }) => {
  const [tabValue, setTabValue] = React.useState("uploaded");
  const [anchorEl, setAnchorEl] = useState({});
  const [openUploadDialog, setOpenUploadDialog] = React.useState(false);
  const [documentType, setDocumentType] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedDocs, setuploadedDocs] = useState([]);
  const [adminDocs, setAdminDocs] = useState([]);
  const [viewingDoc, setViewingDoc] = useState(null); // State for the document being viewed
  const [documentUrl, setDocumentUrl] = useState("");
  const [isdownload, setIsdownload] = useState(false);
  const [filetoDownload, setFileToDownload] = useState(null);
  const [menuIndex, setMenuIndex] = useState(null);
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [actionToConfirm, setActionToConfirm] = useState({ actionType: null, docId: null, actionText: null });

  const handleOpenConfirmation = (actionType, docId, actionText) => {
    setActionToConfirm({ actionType, docId, actionText });
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setActionToConfirm({ actionType: null, docId: null, actionText: null });
    handleMenuClose();
  };

  const handleConfirmAction = () => {
    const { actionType, docId, actionText} = actionToConfirm;
    if (actionType && docId) {
      switch (actionType) {
        case 'Delete':
          deleteDocuments(docId)
          break;
        default:
          break;
      }
    }
    setOpenConfirmation(false);
    setActionToConfirm({ actionType: null, docId: null });
  };


  const navigate = useNavigate();
  const getDocuments = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_ENVIRONMENT}/api/document/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setuploadedDocs(response.data.data);
      // alert(response.data.message)
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
        // showSnackbar(error.response.data.message, 'error');
      }
    } 
    setLoading(false)
  };

  useEffect(() => {
    getDocuments();
  }, [token]);

  const deleteDocuments = async (docId) => {
    handleMenuClose();
    setLoading(true)
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_ENVIRONMENT}/api/document/${docId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSnackbar('Document is deleted.', 'success');
      getDocuments();
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
        // showSnackbar(error.response.data.message, 'error');
      }
    } 
    setLoading(false)
  };

  const getAdminDocs = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_ENVIRONMENT}/api/document/admin-docs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAdminDocs(response.data.data);
      // alert(response.data.message)
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
        // showSnackbar(error.response.data.message, 'error');
      }
    } 
    setLoading(false)
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleUploadClick = () => {
    setOpenUploadDialog(true);
    setSelectedFiles([]);
  };

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
    setDocumentType("")
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files); // Convert FileList to an array
    // Check for duplicates
    const uniqueNewFiles = newFiles.filter(
      (file) =>
        !selectedFiles.some((selectedFile) => selectedFile.name === file.name)
    );
    setSelectedFiles((prevFiles) => [...prevFiles, ...uniqueNewFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files); // Get dropped files
  
    // Check for duplicates
    const uniqueNewFiles = newFiles.filter(
      (file) =>
        !selectedFiles.some((selectedFile) => selectedFile.name === file.name)
    );
  
    // Update the selectedFiles state
    setSelectedFiles((prevFiles) => [...prevFiles, ...uniqueNewFiles]);
  };

  useEffect(() => {
  }, [selectedFiles]);

  const uploadFormData = {
    doc_type: documentType ? documentType : "other",
    description: description,
    docs: selectedFiles,
  };

  const handleUpload = async () => {
    setUploading(true);
    const formData = new FormData();
    formData.append("doc_type", documentType ? documentType : "other");
    formData.append("description", description);
    selectedFiles.forEach((file) => {
      formData.append("docs", file);
    });
    setLoading(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENVIRONMENT}/api/document`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSnackbar('Documents uploaded successfully.', 'success');
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
        // showSnackbar(error.response.data.message, 'error');
      }
    } 
    setUploading(false);
    setOpenUploadDialog(false);
    getDocuments();
    setLoading(false)
  };

  const handleDocumentUrlSet = (url) => {
    setDocumentUrl(url);
  };

  const CurrentDate = () => {
    const currentDate = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return currentDate.toLocaleDateString(undefined, options);
  }  

  const documentsSharedWithYou = [];

  const getIcon = (type) => {
    const docType = type.split(".")[type.split(".").length - 1].toLowerCase();
    switch (docType) {
      case "pdf":
        return <PictureAsPdf color="error" />;
      case "png":
      case "jpg":
      case "jpeg":
      case "img":
        return <ImageOutlinedIcon color="primary" />;
      default:
        return null;
    }
  };

  const getCorrectFileName = (path) => {
    const parts = path.split("/");
    return parts[parts.length - 1];
  };

  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    const options = { month: "long", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleRemoveFile = (indexToRemove) => {
    setSelectedFiles(
      selectedFiles.filter((_, index) => index !== indexToRemove)
    );
  };


  const fetchDocumentForViewing = async (url) => {
    setLoading(true);
    setDocumentUrl("");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENVIRONMENT}/api/document/view-document`, 
        {
          params: { s3Url: url },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDocumentUrl(response.data);
      setLoading(false)
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, "error");
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, "error");
        }
      } else {
        showSnackbar(error.message, "error");
        // showSnackbar(error.response.data.message, 'error');
      }
    }
  };

  const initiateDownload = async (url) => {
    handleMenuClose();
    setLoading(true);
  
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENVIRONMENT}/api/document/download-document`,
        {
          params: { s3Url: url },
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      saveAs(response.data, url.split('/').pop());
  
      setIsdownload(false);
      setFileToDownload(null);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
      }
    }
  };


  const handleViewDocument = (doc) => {
    setViewingDoc(doc);
    setIsdownload(false);
    fetchDocumentForViewing(doc.path);
  };


  const handleCloseViewer = () => {
    setViewingDoc(null);
    setIsdownload(false);
  };

  const handleDownload = () => {
    setIsdownload(true);
    initiateDownload(viewingDoc.path);
    setViewingDoc(null);
  };

  const handleDocDownloadMenu = (doc) => {
    handleMenuClose();
    setFileToDownload(doc);
    setIsdownload(true);
    initiateDownload(doc.path);
  };

  const handleMenuClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuIndex(null);
  };

  const renderDocumentList = (documents, user) => (
    <List sx={{
      pt: 0,
    }}>
{documents && documents.length > 0 && (
  documents.map((doc, index) => (
    <Paper
      key={doc._id}
      variant="document"
      sx={{
        marginBottom: 2,
        backgroundColor: "white",
        boxShadow: "none",
        border: "1px solid #e0e0e0",
      }}
    >
      <ListItem
        sx={{
          ml: "3%",
        }}
      >
        <ListItemIcon sx={{ minWidth: 37 }}>
          {getIcon(doc.original_name)}
        </ListItemIcon>
        <ListItemText
          onClick={() => handleViewDocument(doc)}
          sx={{ cursor: "pointer" }}
          primary={
            <Typography variant="body4" sx={{ fontFamily: 'Inter'}}>
              {getCorrectFileName(doc.path)}
            </Typography>
          }
          secondary={
          <>  
           <Box sx={{ width: '99%'}}>
            <Typography
              variant="body8"
              // color="text.secondary"
              sx={{ fontStyle: 'italic', fontWeight: "bold", fontFamily: 'Inter' }}
            >
          {doc.description}
        </Typography>
        </Box>
            <Box display="flex" alignItems="center" sx={{ mt: 0.7 }}>
              <Box sx={{ minWidth: "30%", display: "flex" }}>
                <Person
                  sx={{
                    marginRight: 0.5,
                    color: "#7B8291",
                    fontSize: 16,
                  }}
                />
                <Typography
                  variant="body8"
                  sx={{ marginRight: 1, fontWeight: "bold", fontFamily: 'Inter' }}
                >
                  {`${doc.uploaded_by.first_name} ${doc.uploaded_by.last_name}`}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mr: [0,30] }}>
                <AccessTimeIcon
                  sx={{
                    marginRight: 0.5,
                    color: "#7B8291",
                    fontSize: 16,
                  }}
                />
                <Typography variant="body8" sx={{ fontWeight: "bold" }}>
                  Added {formatDate(doc.createdAt)}
                </Typography>
              </Box>
            </Box>
            </>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            onClick={(event) => handleMenuClick(event, index)}
          >
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && menuIndex === index}
            onClose={handleMenuClose}
            sx={{
              mt: 0,
              "& .MuiPaper-root": {
                backgroundColor: "#ffffff",
                minWidth: "10em",
              },
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {user === 'patient' && (
            <MenuItem onClick={() => handleOpenConfirmation('Delete', doc._id,'Are you sure you want to delete this document?')}>
            Delete
          </MenuItem>
            )}

            <MenuItem onClick={() => handleDocDownloadMenu(doc)}>
              Download
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
    </Paper>
  ))
)}

    </List>
  );

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          textColor="inherit"
          TabIndicatorProps={{
            style: { backgroundColor: "#7B26C9", height: 5 },
          }}
        >
          <Tab
            value="uploaded"
            label="Documents Uploaded by You"
            textColor="inherit"
            sx={{
              textTransform: "none",
              color: tabValue === "uploaded" ? "#7B26C9" : "#525968",
              fontWeight: tabValue === "uploaded" ? 800 : 400,
              fontSize: 14,
              fontFamily: "Inter",
              "&:hover": {
                color: "#7B26C9", // Text color on hover
                opacity: 0.7, // Reduce opacity on hover
                borderBottom: "2px solid #7B26C9", // Bottom border on hover
                transition: "all 0.3s ease", // Smooth transition
              },
            }}
          />
          <Tab
            value="shared"
            label="Documents Shared with You"
            textColor="inherit"
            sx={{
              textTransform: "none",
              color: tabValue === "shared" ? "#7B26C9" : "#525968",
              fontWeight: tabValue === "shared" ? 800 : 400,
              fontSize: 14,
              fontFamily: "Inter",
              "&:hover": {
                color: "#7B26C9", // Text color on hover
                opacity: 0.7, // Reduce opacity on hover
                borderBottom: "2px solid #7B26C9", // Bottom border on hover
                transition: "all 0.3s ease", // Smooth transition
              },
            }}
            onClick={getAdminDocs}
          />
        </Tabs>
      </Box>

      <hr
        style={{
          borderTop: "1px solid #CCCCCC",
          width: "100%",
          marginTop: 0,
        }}
      />
      <Box sx={{ marginTop: 2, ml: [0, 13], mr: [0, 13] }}>
        {tabValue === "uploaded" && (
          <>
            <Button
              variant="contained"
              // startIcon={<BackupOutlinedIcon />}
              sx={{ marginBottom: 5, mt: 4 }}
              size="large"
              onClick={handleUploadClick}
            >
              <Typography
                variant="caption"
                sx={{
                  fontFamily: "Inter",
                  fontSize: 16,
                }}
              >
                Upload Document
              </Typography>
            </Button>

            {uploadedDocs && uploadedDocs.length > 0 ? (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontFamily: "Inter" }}
                >
                  Your Documents
                </Typography>
                <Typography variant="h6" sx={{ mt: 4, fontFamily: "Inter" }}>
                  {CurrentDate()}
                </Typography>
              </>
            ) : (
              <Typography variant="body1" sx={{ fontFamily: "Inter" }}>
                You have not uploaded any documents yet.
              </Typography>
            )}

            {uploadedDocs &&
              uploadedDocs.length > 0 &&
              renderDocumentList(uploadedDocs, "patient")}
          </>
        )}

        {tabValue === "shared" && (
          <>
            {adminDocs && adminDocs.length > 0 ? (
              <>
                {/* <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Inter' }}>
          Your Documents
        </Typography> */}
                <Typography variant="h6" sx={{ mt: 4, fontFamily: "Inter" }}>
                  {CurrentDate()}
                </Typography>
                {renderDocumentList(adminDocs, "admin")}
              </>
            ) : (
              <Typography variant="body1" sx={{ fontFamily: "Inter" }}>
                There are no documents shared with you yet.
              </Typography>
            )}
          </>
        )}
      </Box>
      <div style={{ background: "#ffffff" }}>
        <Dialog
          open={openUploadDialog}
          onClose={handleCloseUploadDialog}
          PaperProps={{
            sx: { backgroundColor: "#ffffff", maxWidth: "23.10em" },
          }}
        >
          <DialogTitle>
            <Typography variant="modalHeading" sx={{ fontFamily: "Inter" }}>
              Upload Document
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleCloseUploadDialog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <FormControl fullWidth variant="outlined">
                  <FormLabel required>
                    <Typography variant="label2" sx={{ fontFamily: "Inter" }}>
                      Document Type
                    </Typography>
                  </FormLabel>
                  <Select
                    value={documentType}
                    onChange={(e) => setDocumentType(e.target.value)}
                    input={<OutlinedInput label="Document Type" />}
                    displayEmpty
                    MenuProps={{
                      sx: {
                        "& .MuiPaper-root": {
                          backgroundColor: "#ffffff",
                          fontFamily: "Inter",
                        },
                        "& .MuiSelect-select": {
                          color: "grey",
                          backgroundColor: "#ffffff",
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="ID Proof" selected>
                      ID Proof
                    </MenuItem>
                    <MenuItem value="Insurance">Insurance</MenuItem>
                    <MenuItem value="Test Report">Test Report</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <FormLabel
                    htmlFor="description"
                    // sx={{
                    //   textAlign: "left",
                    //   fontSize: 12,
                    //   fontWeight: "medium",
                    // }}
                  >
                    <Typography variant="label2" sx={{ fontFamily: "Inter" }}>
                      Description
                    </Typography>
                  </FormLabel>
                  <OutlinedInput
                    id="description"
                    name="description"
                    type="text"
                    placeholder="Enter a description"
                    multiline
                    rows={4} // Set the number of rows to define the height
                    sx={{ width: "100%", minHeight: 100, fontFamily: "Inter" }} // Custom styles for width and minimum height
                    // onChange={handleChange}
                    inputProps={{
                      style: {
                        paddingLeft: "0",
                        fontWeight: 300,
                        marginRight: 50,
                        fontSize: 14,
                        fontFamily: "Inter",
                        color: "#525968",
                      },
                    }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    border: "2px dashed #BAC3CE",
                    padding: 2,
                    textAlign: "center",
                    marginTop: 2,
                    cursor: "pointer",
                  }}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <input
                    type="file"
                    accept=".pdf,.jpeg,.jpg,.png"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="file-upload"
                    multiple="multiple"
                  />
                  <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
                    {/* {selectedFile ? (
                    selectedFile.name
                  ) : ( */}
                    <>
                      <Typography variant="body1">
                        <BackupOutlinedIcon sx={{ fontSize: 30 }} />
                      </Typography>
                      <Typography variant="body6" sx={{ fontFamily: "Inter" }}>
                        Drop file here, or{" "}
                        <Link
                          style={{
                            color: "#43349C",
                            textDecoration: "none",
                            fontFamily: "Inter",
                          }}
                        >
                          click to upload
                        </Link>
                      </Typography>
                      <Box sx={{ ml: 7, mr: 7 }}>
                        <Typography
                          variant="body5"
                          sx={{ fontWeight: 600, fontFamily: "Inter" }}
                        >
                          (PDF, JPEG, JPG, or PNG. Max file size 10 MB)
                        </Typography>
                      </Box>
                    </>
                    {/* )} */}
                  </label>
                </Box>
              </Grid>
              {selectedFiles &&
                selectedFiles.map((file, index) => (
                  <Grid item xs={12} key={index}>
                    <Box
                      sx={{
                        // border: "1px solid grey",
                        // padding: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: 1,
                        bgcolor: "#EDEFF3",
                      }}
                    >
                      <Typography
                        variant="body7"
                        sx={{ textAlign: "left", ml: 2, fontFamily: "Inter" }}
                      >
                        {file.name}
                      </Typography>
                      <IconButton onClick={() => handleRemoveFile(index)}>
                        <Close sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseUploadDialog}
              variant="outlined"
              color="primary"
            >
              <Typography variant="caption" sx={{ fontFamily: "Inter" }}>
                Cancel
              </Typography>
            </Button>
            <Button
              onClick={handleUpload}
              variant="contained"
              color="primary"
              disabled={!documentType || !selectedFiles.length || uploading}
              startIcon={uploading ? <CircularProgress size={24} /> : null}
            >
              <Typography
                variant="buttonText"
                size="large"
                sx={{
                  fontFamily: "bold",
                  fontSize: 16,
                  fontFamily: "Inter",
                }}
              >
                {uploading ? "Uploading" : "Upload"}
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={viewingDoc !== null}
          onClose={handleCloseViewer}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ flexGrow: 1, fontFamily: "Inter" }}
            >
              {viewingDoc !== null
                ? getCorrectFileName(viewingDoc.path)
                : "Document Viewer"}
            </Typography>
            <div style={{ display: "flex" }}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleDownload}
                aria-label="download"
                sx={{ mr: 1 }}
              >
                <DownloadIcon />
              </IconButton>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseViewer}
                aria-label="close"
              >
                <Close />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
              padding: 0,
              overflow: "auto",
            }}
          >
            {documentUrl && (
              <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
                {viewingDoc?.path.endsWith(".pdf") ? (
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer fileUrl={documentUrl} />
                  </Worker>
                ) : (
                  <img
                    src={documentUrl}
                    alt="Document"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                )}
              </div>
            )}
          </DialogContent>
        </Dialog>

        {isdownload && (
          <div style={{ display: "none" }}>
            <iframe
              src={
                viewingDoc !== null
                  ? viewingDoc.path
                  : filetoDownload !== null
                  ? filetoDownload.path
                  : ""
              }
              width="100%"
              height="500px"
              title="Document"
            />
          </div>
        )}
      </div>
      {loading && <LoadingSpinner />}
      <ConfirmationDialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        onConfirm={handleConfirmAction}
        actionType={actionToConfirm.actionType}
        docId={actionToConfirm.docId}
        actionText={actionToConfirm.actionText}
      />
    </Box>
  );
};

export default DocumentList;
