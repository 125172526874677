import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getCheckoutTheme from "./getCheckoutTheme";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';

const ConfirmationDialog = ({ open, onClose, onConfirm, actionType, actionText }) => {
  const checkoutTheme = createTheme(getCheckoutTheme("light"));
  return (
    <ThemeProvider theme={checkoutTheme}>
      <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <ReportGmailerrorredOutlinedIcon style={{ color: '#5B43BD', marginRight: '8px' }} />
          <Typography
            variant="caption"
            sx={{
              fontSize: 18,
              fontFamily: 'Inter',
              fontWeight: 700,
              color: '#000000'
            }}
          >
            Confirmation
          </Typography>
        </Box>
      </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {actionText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', ml:2,mr:2, mb:2 }}>
            <Button
              variant="outlined"
              sx={{
                marginBottom: { xs: 1, md: 0 },
                mt: { xs: 1, md: 0 },
                width: { xs: '100%', md: 'auto' },
              }}
              size="large"
              onClick={onClose}
            >
              <Typography
                variant="caption"
                sx={{
                  fontSize: 14,
                  fontFamily: 'Inter',
                  fontWeight: 600,
                }}
              >
                Cancel
              </Typography>
            </Button>
            <Button
              variant="contained"
              sx={{
                marginBottom: { xs: 1, md: 0 },
                mt: { xs: 1, md: 0 },
                width: { xs: '100%', md: 'auto' },
              }}
              size="large"
              onClick={onConfirm}
            >
              <Typography
                variant="caption"
                sx={{
                  fontSize: 14,
                  fontFamily: 'Inter',
                  fontWeight: 600,
                }}
              >
                {actionType}
              </Typography>
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ConfirmationDialog;
