import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  Typography,
  Box,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getCheckoutTheme from "./getCheckoutTheme";
import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/system";
import { MuiOtpInput } from "mui-one-time-password-input";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PurplegradientIcon from "./Purplegradient/PurplegradientIcon";
import OtpInput from "react-otp-input";
import axios from "axios";
import Cookies from "js-cookie";
import { useSnackbar } from './SnackBar';
import LoadingSpinner from './LoadingSpinner'; 

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const LoginForm = () => {
  const checkoutTheme = createTheme(getCheckoutTheme("light"));
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const location = useLocation();
  const { responseData } = location.state || {};
  const { showSnackbar } = useSnackbar();
  const [validationMessage, setValidationMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENVIRONMENT}/api/auth/verify_otp`,
        {
          phone: responseData.mobile,
          otp: otp,
        }
      );
      const { token, data } = response.data;
      Cookies.set("patientToken", token);
      Cookies.set("patientData", JSON.stringify(data));
      showSnackbar(response.data.message, 'success');
      navigate("/home");
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          setValidationMessage(error.response.data.message)
        }
      } else {
        showSnackbar(error.message, 'error');
      }
    }
    setLoading(false)
  };

  return (
    <ThemeProvider theme={checkoutTheme}>
      <div
        style={{
          width: "100%",
          height: "100vh",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
          overflow: "hidden",
        }}
      >
        <PurplegradientIcon style={{ width: "100%", minHeight: "100vh" }} />
      </div>
      <Container
        maxWidth="xs"
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem 3rem 1rem 4rem",
            boxShadow: "4px 2px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "1rem",
            background: "linear-gradient(to right, #FFFFFF, #d1e8ff)",
            width: "100%",
          }}
        >
          <Typography
            variant="heading"
            gutterBottom
            sx={{ textAlign: "left", color: "#43349C", marginBottom: "2em" }}
          >
            Patient Portal
          </Typography>
          <Typography
            variant="subheading"
            // gutterBottom
            sx={{ textAlign: "left" }}
          >
            Verification
          </Typography>
          <Typography variant="text" gutterBottom sx={{ textAlign: "left" }}>
          Please enter the OTP sent to your phone number.
          </Typography>
          {responseData.opt && (
            <Typography variant="text" gutterBottom sx={{ textAlign: "left" , fontFamily: 'Inter'}}>
              Your OTP is {responseData.opt}
            </Typography>
          )}

          <Grid container spacing={3} marginTop={1}>
            {/* {loading && ( */}
            <FormGrid item xs={12}>
              <OtpInput
                numInputs={6}
                value={otp}
                onChange={setOtp}
                renderSeparator={<span></span>}
                renderInput={(props, index) => (
                  <input
                    {...props}
                    inputMode="numeric" // Ensure numeric keyboard on mobile
                    style={{
                      width: "2em",
                      height: "2em",
                      fontSize: "16px",
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                      marginRight: "0.3em",
                      textAlign: "center", // Optional: center text in each input
                    }}
                  />
                )}
                containerStyle={{
                  justifyContent: "center",
                }}
              />
            </FormGrid>
            {/* )} */}
            <Grid item xs={12} sx={validationMessage ? { mt: 0, pt: '10px !important' } : { mt: 0 }}>
              {validationMessage && (
                <Typography variant="validationMessage" sx={{ color: 'red',mt: 0, pt:1 }}>
                  {validationMessage}
                </Typography>
              )}
              <Button
                variant="contained"
                fullWidth
                onClick={handleLogin}
                style={{ borderRadius: "25vw", marginTop: 25 }}
                // disabled={otp.length === 6}
              >
                <Typography variant="buttonText">Verify</Typography>
              </Button>
            </Grid>
          </Grid>
          <Typography
            variant="text"
            gutterBottom
            sx={{ textAlign: "center", marginTop: "3em", fontFamily: 'Inter' }}
          >
            <Link
              to="/"
              style={{ color: "#43349C", textDecoration: "none", }}
            >
              <Typography variant="link" sx={{ fontFamily: 'Inter'}}>
              Back to Login
              </Typography>
            </Link>
          </Typography>
        </Box>
      </Container>
      {loading && <LoadingSpinner />}
    </ThemeProvider>
  );
};

export default LoginForm;
