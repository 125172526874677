import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  Typography,
  Box,
  Grid,
  InputAdornment,
  OutlinedInput,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Select,
  MenuItem,
  IconButton
} from "@mui/material";
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getCheckoutTheme from "./getCheckoutTheme";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PurplegradientIcon from "./Purplegradient/PurplegradientIcon";
import CheckIcon from '@mui/icons-material/Check';
import axios from "axios";
import AppBar from "./AppBar";
import PateintDocument from "./PatientDocument";
import Auth from "./Auth";
import Cookies from 'js-cookie';
import { useSnackbar } from './SnackBar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import LoadingSpinner from './LoadingSpinner'; 

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const GreyBackgroundBox = styled(Box)({
  backgroundColor: "#edeff3",
  minHeight: "100vh",
  minWidth: '100vw'
});

const PateintProfile = () => {

  const { showSnackbar } = useSnackbar();
  const checkoutTheme = createTheme(getCheckoutTheme("light"));
  const navigate = useNavigate();

  const [userProfileData, setUserProfileData] = useState('');
  const [email,setEmail] = useState('');
  const [emergencyContact,setEmergencyContact] = useState('');
  const [gender, setGender]= useState('')
  const [address1,setAddress1] = useState('');
  const [address2,setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [bloodGroup, setBloodGroup] = useState('');
  const[consentToText, setConsentToText] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showFullAadhaar, setShowFullAadhaar] = useState(false);
  const [maskedAadhaar, setMaskedAadhaar] = useState('');

  const [showFullPan, setShowFullPan] = useState(false);
  const [maskedPan, setMaskedPan] = useState('');

  const [timeoutId, setTimeoutId] = useState(null);
  const [timeoutIdPan, setTimeoutIdPan] = useState(null);
  const [aadhaarNumber, setAadhaarNumber] = useState('');
  const [panNumber, setPanNumber] = useState('');

  const [isAadhaarValid, setIsAadhaarValid] = useState(true);
  const [isPanEdited, setIsPanEdited] = useState(true);


  // List of states in India for the dropdown
  const indianStates = [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar',
    'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh',
    'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra',
    'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha',
    'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana',
    'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal',
    'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli',
    'Daman and Diu', 'Delhi', 'Lakshadweep', 'Puducherry'
  ];

  const [emailError, setEmailError] = useState(false);
  const [isAddressEdited, setIsAddressEdited] = useState(false);

  const [emailBorderColor, setEmailBorderColor] = useState('');
  
  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  
    if (emailRegex.test(email)) {
      setEmailError(false);
      setEmailBorderColor(''); // Reset border color if email is valid
      return true;
    } else {
      setEmailError(true);
      setEmailBorderColor('red'); // Set border color to red if email is invalid
      return false;
    }
  };

  const getFieldProps = (fieldName) => {
    const value = eval(fieldName); // Using eval here, consider alternative safer approach
  
    return {
      name: fieldName,
      onChange: handleChange,
      value: value,
      error: isAddressEdited && value === "" ? true : false,
      helperText: isAddressEdited && value === "" ? "This field is required" : "",
      required: isAddressEdited && value !== '', // Set required based on isAddressEdited and value presence
      style: {
        borderColor: isAddressEdited && value === "" ? "red" : "", // Adjust border color based on conditions
      }
    };
  };
  
  

  const handleChangeState = (event) => {
    setState(event.target.value);
    setIsAddressEdited(true);
  };

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const token = Cookies.get('patientToken');
  if (!token) {
    navigate('/');
  }

  const getUser = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENVIRONMENT}/api/users/user-profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserProfileData(response.data.user);
      setEmail(response.data.user.email);
      setGender(response.data.user.gender || '')
      setEmergencyContact(response.data.user.emergency_contact || '');
      setAddress1(response.data.user.address ? response.data.user.address.address1 : '')
      setAddress2(response.data.user.address ? response.data.user.address.address2 : '');
      setCity(response.data.user.address ? response.data.user.address.city : '');
      setState(response.data.user.address ? response.data.user.address.state : '');
      setZip(response.data.user.address ? response.data.user.address.zip : '');

      setHeight(response.data.user.height ? response.data.user.height : '');
      setWeight(response.data.user.weight ? response.data.user.weight : '');
      setBloodGroup(response.data.user.blood_group ? response.data.user.blood_group : '');
     setConsentToText(response.data.user.has_consent_enabled ? response.data.user.has_consent_enabled : false);
     setAadhaarNumber(response.data.user.aadhaar_number ? response.data.user.aadhaar_number : '');
     setPanNumber(response.data.user.pan_number ? response.data.user.pan_number : '');
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
        // showSnackbar(error.response.data.message, 'error');
      }
    } 
    setLoading(false)
  };

  useEffect(() => {
    getUser();
  }, [token]);

  const [data, setData] = useState(false);

  const isFormValid = () => {
    if (isAddressEdited) {
      return (
        // email !== "" &&
        // emergencyContact !== "" &&
        address1 !== "" &&
        city !== "" &&
        state !== "" &&
        zip !== ""
      );
    } 
    else {
      // return (
      //   email !== "" &&
      //   emergencyContact !== ""
      // );
      return true
    }
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
  
    // Update state values based on field name
    switch (name) {
      case 'email':
        setEmail(value);
        validateEmail(value);
        break;
      case 'emergencyContact':
        setEmergencyContact(value);
        break;
        case 'height':
          // Allow only numbers, single quote ('), and double quote (")
          const heightValue = value.replace(/[^0-9'"]/g, '');
          setHeight(heightValue);
          break;
        case 'weight':
          // Allow only numbers and a dot (.)
          const weightValue = value.replace(/[^0-9.]/g, '');
          setWeight(weightValue);
          break;
      case 'bloodGroup':
        setBloodGroup(value);
        break;
      case 'address1':
        setAddress1(value);
        break;
      case 'address2':
        setAddress2(value);
        break;
      case 'city':
        setCity(value);
        break;
      case "consentToText":
        setConsentToText(checked);
        break;
      case 'zipcode':
        setZip(value);
        break;

      default:
        break;
    }
  };

  const handleAadhaarNumberChange = (event) => {
    const { value, name } = event.target;
    const cleanedValue = value.replace(/\D/g, '');
    let formattedAadhaar = '';
    for (let i = 0; i < cleanedValue.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedAadhaar += '-';
      }
      formattedAadhaar += cleanedValue.charAt(i);
    }
    setAadhaarNumber(formattedAadhaar.slice(0, 14))
  };
  
  

  const handlePanCardChange = (event) => {
    const { value, name } = event.target;
    const upperCaseValue = value.toUpperCase();

    const firstFiveChars = upperCaseValue.slice(0, 5).replace(/[^A-Z]/g, '');
    const nextFourDigits = upperCaseValue.slice(5, 9).replace(/\D/g, '');
    const lastChar = upperCaseValue.slice(9, 10).replace(/[^A-Z]/g, '');
    
    const panCard = `${firstFiveChars}${nextFourDigits}${lastChar}`;
    setPanNumber(panCard);
  };


  useEffect(() => {
    const anyFieldNotEmpty = address1 !== '' || city !== '' || state !== '' || zip !== '';
    setIsAddressEdited(anyFieldNotEmpty);
  }, [address1,city,state,zip]);

  useEffect(() => {
    const anyFieldNotEmpty = !( aadhaarNumber.length === 0 || aadhaarNumber.length === 14 );
    setIsAadhaarValid(anyFieldNotEmpty);
  }, [aadhaarNumber]);

  useEffect(() => {
    const anyFieldNotEmpty = !( panNumber.length === 0 || panNumber.length === 10 );
    setIsPanEdited(anyFieldNotEmpty);
  }, [panNumber]);
  
  

  const handleNumericInputChange = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 10);
    setEmergencyContact(numericValue);
  };

  const handleZipInputChange = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 6);
    setZip(numericValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.put(`${process.env.REACT_APP_ENVIRONMENT}/api/users`, {
        email: email,
        gender: gender,
        emergency_contact: emergencyContact,
        height,
        weight,
        blood_group: bloodGroup,
        has_consent_enabled: consentToText,
        aadhaar_number: aadhaarNumber,
        pan_number: panNumber,
        address: {
          address1: address1,
          address2: address2,
          city: city,
          state: state,
          zip: zip
        }
      },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        showSnackbar(`Profile updated successfully.`, 'success');
      getUser();
      // navigate('/home');
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
        // showSnackbar(error.response.data.message, 'error');
      }
    } 
    setLoading(false);
  };


// Function to toggle visibility of Aadhaar number
// const handleToggleVisibility = () => {
//   setShowFullAadhaar(!showFullAadhaar);
//   if (!showFullAadhaar) {
//     setMaskedAadhaar(maskAadhaar(userProfileData.aadhaar_number));
//   } else {
//     setMaskedAadhaar('');
//   }
// };

const handleToggleVisibility = () => {
  setShowFullAadhaar((prevShowFullAadhaar) => {
    // Toggle showFullPan state
    const newShowFullAadhaar = !prevShowFullAadhaar;

    // Clear previous timeout if exists
    if (timeoutId) clearTimeout(timeoutId);

    // Set new timeout to hide after 10 seconds if unhidden
    if (newShowFullAadhaar) setTimeoutId(setTimeout(() => setShowFullAadhaar(false), 10000));

    return newShowFullAadhaar;
  });
};


// Function to mask Aadhaar number except for the last four digits
const maskAadhaar = (aadhaarNumber) => {
  if (aadhaarNumber.length > 4) {
    const maskedPart = '****-****-';
    const visiblePart = aadhaarNumber.slice(-4);
    return maskedPart + visiblePart;
  }
  return aadhaarNumber;
};

// Function to toggle visibility of Aadhaar number
const handleToggleVisibilityForPan = () => {
  setShowFullPan((prevShowFullPan) => {
    // Toggle showFullPan state
    const newShowFullPan = !prevShowFullPan;

    // Clear previous timeout if exists
    if (timeoutIdPan) clearTimeout(timeoutIdPan);

    // Set new timeout to hide after 10 seconds if unhidden
    if (newShowFullPan) setTimeoutIdPan(setTimeout(() => setShowFullPan(false), 10000));

    return newShowFullPan;
  });
};

// Function to mask Aadhaar number except for the last four digits
const maskPan = (panNumber) => {
  if (panNumber.length > 4) {
    const maskedPart = '****-****-';
    const visiblePart = panNumber.slice(-4);
    return maskedPart + visiblePart;
  }
  return panNumber;
};



  return (
    <ThemeProvider theme={checkoutTheme}>
      <GreyBackgroundBox>
        <AppBar />
        <Box
          sx={{ mt: 2, mb: 0, display: "flex", alignItems: "center", ml: 3 }}
        >
          <Breadcrumbs
            separator={
              <NavigateNextIcon
                fontSize="small"
                sx={{ color: "#7B26C9", ml: -1, mr: -1 }}
              />
            }
            aria-label="breadcrumb"
          >
            <Typography
              component="div"
              sx={{
                color: "#7B26C9",
                textDecoration: "none",
                cursor: "pointer",
                borderBottom: "2px solid #7B26C9",
                display: "inline-block",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
              onClick={() => navigate("/home")}
            >
              Home
            </Typography>
            <Typography color="text.primary" sx={{ color: "#7B26C9" }}>
              Profile
            </Typography>
          </Breadcrumbs>
        </Box>
        <Container sx={{ backgroundColor: "#ffffff", pb: 1 }}>
          <Box sx={{ mt: 4 }}>
            <Container
              maxWidth="md"
              sx={{ bgcolor: "#ffffff", p: 4, mt: 4, mb: 4, borderRadius: 2 }}
            >
              <Box component="form" noValidate autoComplete="off">
                <hr
                  style={{
                    borderTop: "1px solid #CCCCCC",
                    width: "100%",
                    margin: "8px 0",
                  }}
                />
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4} sx={{ mb: "3%" }}>
                    <Typography variant="buttonText2" gutterBottom>
                      Login Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="phoneNumber"
                        sx={{ textAlign: "left" }}
                        required
                      >
                        <Typography variant="label">Phone Number</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="phoneNumber"
                        name="phoneNumber"
                        type="text"
                        placeholder="Phone Number"
                        value={
                          userProfileData.phone ? userProfileData.phone : ""
                        }
                        onChange={handleNumericInputChange}
                        required
                        disabled
                        inputProps={{
                          inputMode: "numeric", // This will show numeric keyboard on mobile devices
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel htmlFor="email" sx={{ textAlign: "left" }}>
                        <Typography variant="label">Email Address</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Your email address"
                        onChange={(e) => {
                          handleChange(e);
                          validateEmail(e.target.value);
                        }}
                        value={email}
                        required={isAddressEdited}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}></Grid>
                </Grid>

                <hr
                  style={{
                    borderTop: "1px solid #CCCCCC",
                    width: "100%",
                    margin: "8px 0",
                  }}
                />

                {/* Patient Details */}
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4} sx={{ mb: "3%" }}>
                    <Typography variant="buttonText2" gutterBottom>
                      Patient Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="firstName"
                        sx={{ textAlign: "left" }}
                        required
                      >
                        <Typography variant="label">First Name</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="firstName"
                        name="firstName"
                        type="text"
                        placeholder="First name"
                        onChange={handleChange}
                        value={
                          userProfileData.first_name
                            ? userProfileData.first_name
                            : ""
                        }
                        required
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="lastName"
                        sx={{ textAlign: "left" }}
                        required
                      >
                        <Typography variant="label">Last Name</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="lastName"
                        name="lastName"
                        type="text"
                        placeholder="Last name"
                        onChange={handleChange}
                        value={
                          userProfileData.last_name
                            ? userProfileData.last_name
                            : ""
                        }
                        required
                        disabled
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="gender"
                        sx={{ textAlign: "left" }}
                        // required
                        required={isAddressEdited}
                      >
                        <Typography variant="label">Gender</Typography>
                      </FormLabel>
                      <Select
                        // labelId="gender-label"
                        id="gender"
                        name="gender"
                        required
                        defaultValue="" // Set default value to empty string
                        displayEmpty // This will make the placeholder visible
                        MenuProps={{
                          sx: {
                            "& .MuiPaper-root": {
                              backgroundColor: "#ffffff",
                            },
                            "& .MuiSelect-select": {
                              color: "grey",
                              backgroundColor: "#ffffff",
                              border: "none",
                            },
                            "& .MuiMenuItem-root": {
                              "&.Mui-focused": {
                                backgroundColor: "transparent",
                                outline: "#ffffff",
                              },
                            },
                          },
                        }}
                        value={gender}
                        onChange={handleChangeGender}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>{" "}
                        {/* Placeholder option */}
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="dateOfBirth"
                        sx={{ textAlign: "left" }}
                        required
                      >
                        <Typography variant="label">Date of Birth</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="dateOfBirth"
                        name="dateOfBirth"
                        type="date"
                        required
                        onChange={handleChange}
                        style={{ color: "#5B6B7C", opacity: 1 }}
                        value={userProfileData.dob ? userProfileData.dob : ""}
                        disabled
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="aadhaarNumber"
                        sx={{ textAlign: "left" }}
                      >
                        <Typography variant="label">Aadhaar Number</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="aadhaarNumber"
                        name="aadhaarNumber"
                        type={showFullAadhaar ? 'text' : 'password'}
                        placeholder="Aadhaar Number"
                        onChange={handleAadhaarNumberChange}
                        value={aadhaarNumber}
                        required
                        // disabled
                        endAdornment={
                          aadhaarNumber && (  // Only render if aadhaarNumber is not empty
                            <InputAdornment position="end">
                              <IconButton onClick={handleToggleVisibility}>
                                {showFullAadhaar ? (
                                  <VisibilityOutlinedIcon fontSize="small" />
                                ) : (
                                  <VisibilityOffOutlinedIcon fontSize="small" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                        style= {{ borderColor: isAadhaarValid=== false ? '' : 'red' }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel htmlFor="panNumber" sx={{ textAlign: "left" }}>
                        <Typography variant="label">PAN Number</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="panNumber"
                        name="panNumber"
                        type={showFullPan ? 'text' : 'password'}
                        placeholder="PAN Number"
                        onChange={handlePanCardChange}
                        // value={
                        //   userProfileData.pan_number
                        //     ? userProfileData.pan_number
                        //     : ""
                        // }
                        required
                        // disabled
                        value={panNumber}
                        // endAdornment={
                        //   <InputAdornment position="end">
                        //     <IconButton onClick={handleToggleVisibilityForPan}>
                        //       {showFullPan ? (
                        //         <VisibilityOutlinedIcon fontSize="small" />
                        //       ) : (
                        //         <VisibilityOffOutlinedIcon fontSize="small" />
                        //       )}
                        //     </IconButton>
                        //   </InputAdornment>
                        // }
                        endAdornment={
                          panNumber && (
                            <InputAdornment position="end">
                              <IconButton onClick={handleToggleVisibilityForPan}>
                                {showFullPan ? (
                                  <VisibilityOutlinedIcon fontSize="small" />
                                ) : (
                                  <VisibilityOffOutlinedIcon fontSize="small" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }

                        style= {{ borderColor: isPanEdited=== false ? '' : 'red' }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="emergencyContact"
                        sx={{ textAlign: "left" }}
                      >
                        <Typography variant="label">
                          Emergency Contact Number
                        </Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="emergencyContact"
                        name="emergencyContact"
                        type="text"
                        placeholder="Emergency phone number"
                        // value={userProfileData.emergency_contact ? userProfileData.emergency_contact : ''}
                        onChange={(e) => handleNumericInputChange(e)}
                        value={emergencyContact}
                        inputProps={{
                          inputMode: "numeric", // This will show numeric keyboard on mobile devices
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={8}>
                    <hr
                      style={{
                        borderTop: "1px solid #CCCCCC",
                        width: "100%",
                        margin: "8px 0",
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={4}>
                  </Grid> */}
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel htmlFor="height" sx={{ textAlign: "left" }}>
                        <Typography variant="label">
                          Height (In feet and inches)
                        </Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="height"
                        name="height"
                        type="text"
                        placeholder="Height (In feet and inches)"
                        // value={userProfileData.emergency_contact ? userProfileData.emergency_contact : ''}
                        onChange={handleChange}
                        value={height}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel htmlFor="weight" sx={{ textAlign: "left" }}>
                        <Typography variant="label">Weight (In kgs)</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="weight"
                        name="weight"
                        type="text"
                        placeholder="Weight (In kgs)"
                        // value={userProfileData.emergency_contact ? userProfileData.emergency_contact : ''}
                        onChange={handleChange}
                        value={weight}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="bloodGroup"
                        sx={{ textAlign: "left" }}
                      >
                        <Typography variant="label">Blood Group</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="bloodGroup"
                        name="bloodGroup"
                        type="text"
                        placeholder="Blood Group"
                        // value={userProfileData.emergency_contact ? userProfileData.emergency_contact : ''}
                        onChange={handleChange}
                        value={bloodGroup}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}></Grid>
                </Grid>
                <hr
                  style={{
                    borderTop: "1px solid #CCCCCC",
                    width: "100%",
                    margin: "8px 0",
                  }}
                />

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4} sx={{ mb: "3%" }}>
                    <Typography variant="buttonText2" gutterBottom>
                      Address
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="address1"
                        sx={{ textAlign: "left" }}
                        required={isAddressEdited}
                      >
                        <Typography variant="label">Address Line 1</Typography>
                      </FormLabel>
                      <OutlinedInput
                        {...getFieldProps("address1")}
                        id="address1"
                        name="address1"
                        type="text"
                        placeholder="Address Line 1"
                        onChange={(e) => handleChange(e)}
                        value={address1}
                        required={isAddressEdited}
                        // required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel htmlFor="address2" sx={{ textAlign: "left" }}>
                        <Typography variant="label">Address Line 2</Typography>
                      </FormLabel>
                      <OutlinedInput
                        // {...getFieldProps("address2")}
                        id="address2"
                        name="address2"
                        type="text"
                        placeholder="Address Line 2"
                        onChange={(e) => handleChange(e)}
                        value={address2}
                        // required={isAddressEdited}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="city"
                        sx={{ textAlign: "left" }}
                        // required
                        required={isAddressEdited}
                      >
                        <Typography variant="label">City</Typography>
                      </FormLabel>
                      <OutlinedInput
                        {...getFieldProps("city")}
                        id="city"
                        name="city"
                        type="text"
                        placeholder="City"
                        onChange={(e) => handleChange(e)}
                        value={city}
                        required={isAddressEdited}
                        // required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="state"
                        sx={{ textAlign: "left" }}
                        required={isAddressEdited}
                        // required
                      >
                        <Typography variant="label">State</Typography>
                      </FormLabel>
                      <Select
                        {...getFieldProps("state")}
                        labelId="state-select-label"
                        id="state-select"
                        value={state}
                        label="Select"
                        onChange={handleChangeState}
                        defaultValue="" // Set default value to empty string
                        displayEmpty // This will make the placeholder visible
                        MenuProps={{
                          sx: {
                            "& .MuiPaper-root": {
                              backgroundColor: "#ffffff",
                            },
                            "& .MuiSelect-select": {
                              color: "grey",
                              backgroundColor: "#ffffff",
                              border: "none",
                            },
                            "& .MuiMenuItem-root": {
                              "&.Mui-focused": {
                                backgroundColor: "transparent",
                                outline: "#ffffff",
                              },
                            },
                          },
                        }}
                        required={isAddressEdited}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        <MenuItem value="Gujarat">Gujarat</MenuItem>
                        <hr /> {/* Add a horizontal line as a partition */}
                        {indianStates.map((stateName, index) => (
                          <MenuItem key={index} value={stateName}>
                            {stateName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="zipcode"
                        sx={{ textAlign: "left" }}
                        required={isAddressEdited}
                        // required
                      >
                        <Typography variant="label">Zipcode</Typography>
                      </FormLabel>
                      <OutlinedInput
                        {...getFieldProps("zip")}
                        id="zipcode"
                        name="zipcode"
                        type="text"
                        placeholder="Zipcode"
                        onChange={(e) => handleZipInputChange(e)}
                        value={zip}
                        // required
                        required={isAddressEdited}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}></Grid>
                </Grid>
              </Box>
              <hr
                style={{
                  borderTop: "1px solid #CCCCCC",
                  width: "100%",
                  margin: "8px 0",
                }}
              />
              <Grid container spacing={2} alignItems="center">
                {/* <Grid item xs={12} md={4}></Grid> */}
                <Grid item xs={12} md={8}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel>
                      <Typography variant="label">Consent to Text</Typography>
                    </FormLabel>
                    <FormControlLabel
                      // style={{ cursor: "not-allowed" }}
                      control={
                        <Checkbox
                          sx={{ "&.Mui-checked": { color: "#7B26C9" } }}
                          checked={consentToText || false}
                        />
                      }
                      name="consentToText"
                      onChange={handleChange}
                      label={
                        <Typography
                          variant="body1"
                          sx={{ fontSize: 12, fontFamily: "Inter" }}
                        >
                          By checking the box I agree to receive automated text
                          notifications.
                        </Typography>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Box
                sx={{
                  mt: 3,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ p: 2, marginLeft: "auto" }}
                  disabled={!isFormValid()} // Disable the button if the form is not valid
                  size="large"
                  onClick={(e) => handleSubmit(e)}
                >
                  <Typography variant="buttonText" sx={{ fontFamily: "Inter" }}>
                    Save
                  </Typography>
                </Button>
              </Box>
            </Container>
          </Box>
        </Container>
        {loading && <LoadingSpinner />}
      </GreyBackgroundBox>
    </ThemeProvider>
  );
};

export default PateintProfile;
