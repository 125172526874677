import React, { useState,useEffect } from "react";
import getCheckoutTheme from "./getCheckoutTheme";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import PurplegradientIcon from "./Purplegradient/PurplegradientIcon";
import { useSnackbar } from './SnackBar';
import dayjs from 'dayjs';
import {
  Container,
  Button,
  Typography,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Grid,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  //   Link,
} from "@mui/material";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import LoadingSpinner from './LoadingSpinner'; 

const SignUpForm = () => {
  const checkoutTheme = createTheme(getCheckoutTheme("light"));
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const FormGrid = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "column",
  }));

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);

  const [isAadhaarValid, setIsAadhaarValid] = useState(true);
  const [isPanEdited, setIsPanEdited] = useState(true);

  const isValidPhoneNumber = (phoneNumber) => {
    // Regular expression to match a phone number (10 digits)
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const isFormValid = () => {
    return (
      //   formData.email !== "" &&
      formData.firstName !== "" &&
      formData.lastName !== "" &&
      isValidPhoneNumber(formData.phoneNumber) &&
      formData.dateOfBirth !== ""
    );
  };

  const [emailError, setEmailError] = useState(false);
  const [emailBorderColor, setEmailBorderColor] = useState('');

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    dateOfBirth: "",
    consentToText: false,
    gender: "",
    height: "",
    weight: "",
    bloodGroup: "",
    aadhaarNumber: "",
    panNumber: ""
    // isCoveredByMedicare: "no",
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Validation and formatting based on input name
    switch (name) {
      case 'height':
        // Allow only numbers, single quote ('), and double quote (")
        const heightValue = value.replace(/[^0-9'"]/g, '');
        setFormData(prevData => ({ ...prevData, [name]: heightValue }));
        break;
        case 'dateOfBirth':
          const date = dayjs(event.target.value);
          const today = dayjs();
          if (date && date.isBefore(today, 'day')) {
            setFormData(prevData => ({ ...prevData, [name]: event.target.value }));
          } else if (!event.target.value) {
            setFormData(prevData => ({ ...prevData, [name]: '' }));
          } else {
            setFormData(prevData => ({ ...prevData, [name]: today.format('YYYY-MM-DD') }));
          }
          break;  
      case 'weight':
        // Allow only numbers and a dot (.)
        const weightValue = value.replace(/[^0-9.]/g, '');
        setFormData(prevData => ({ ...prevData, [name]: weightValue }));
        break;
      default:
        setFormData(prevData => ({
          ...prevData,
          [name]: type === "checkbox" ? checked : value,
        }));
        break;
    }
  };


  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  
    if (emailRegex.test(email)) {
      setEmailError(false);
      setEmailBorderColor(''); // Reset border color if email is valid
      return true;
    } else {
      setEmailError(true);
      // setEmailBorderColor('red'); // Set border color to red if email is invalid
      return false;
    }
  };

  const getFieldProps = (fieldName) => {
    const isEmailField = fieldName;
    const value = formData.email
    
  
    return {
      name: fieldName,
      onChange: handleChange,
      value: value,
      style: {
        borderColor: (isEmailField && emailBorderColor),
      }
    };
  };

  useEffect(() => {
    const anyFieldNotEmpty = !( formData.aadhaarNumber.length === 0 || formData.aadhaarNumber.length === 14 );
    setIsAadhaarValid(anyFieldNotEmpty);
  }, [formData.aadhaarNumber]);

  useEffect(() => {
    const anyFieldNotEmpty = !(  formData.panNumber.length === 0 ||  formData.panNumber.length === 10 );
    setIsPanEdited(anyFieldNotEmpty);
  }, [formData.panNumber]);

  const handleNumericInputChange = (event) => {
    const { value, name } = event.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 10); // Remove non-numeric characters and limit to 10 digits
    setFormData((prevData) => ({
      ...prevData,
      [name]: numericValue,
    }));
  };

  const handleAadhaarNumberChange = (event) => {
    const { value, name } = event.target;
    const cleanedValue = value.replace(/\D/g, '');

    let formattedAadhaar = '';
    for (let i = 0; i < cleanedValue.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedAadhaar += '-';
      }
      formattedAadhaar += cleanedValue.charAt(i);
    }
    setFormData(prevData => ({
      ...prevData,
      [name]: formattedAadhaar.slice(0, 14), // Limit to 14 characters (12 digits + 2 hyphens)
    }));
  };
  
  

  const handlePanCardChange = (event) => {
    const { value, name } = event.target;
    const upperCaseValue = value.toUpperCase();
    
    const firstFiveChars = upperCaseValue.slice(0, 5).replace(/[^A-Z]/g, '');
    const nextFourDigits = upperCaseValue.slice(5, 9).replace(/\D/g, '');
    const lastChar = upperCaseValue.slice(9, 10).replace(/[^A-Z]/g, '');
    
    const panCard = `${firstFiveChars}${nextFourDigits}${lastChar}`;
  
    setFormData(prevData => ({ ...prevData, [name]: panCard }));
  };
  
  
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Show CircularProgress
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENVIRONMENT}/api/auth/signup`,
        {
          phone: formData.phoneNumber,
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          dob: formData.dateOfBirth,
          gender: formData.gender,
          has_consent_enabled: formData.consentToText,
          height: formData.height,
          weight: formData.weight,
          blood_group: formData.bloodGroup,
          aadhaar_number: formData.aadhaarNumber,
          pan_number: formData.panNumber
        }
      );
      setData(response.data);
      showSnackbar('Patient created successfully.', 'success');
      navigate("/signin", {
        state: {
          responseData: {
            opt: response.data.otp,
            mobile: formData.phoneNumber,
          },
        },
      });
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
        // showSnackbar(error.response.data.message, 'error');
      }
    } 
    setLoading(false);
  };

  const today = new Date().toISOString().split('T')[0];

  return (
    <ThemeProvider theme={checkoutTheme}>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
          overflow: "hidden",
        }}
      >
        <PurplegradientIcon style={{ width: "100%", minHeight: "100%" }} />
      </div>
      <div style={{ height: "100%", width: "100vw" }}>
        <style>
          {`
          body, html {
            margin: 0;
            padding: 0;
            // background: #7B26C9;
            height: 100%;
          }
          `}
        </style>
        <Container
          maxWidth="md"
          sx={{ bgcolor: "#ffffff", p: 4, mt: 4, mb: 4, borderRadius: 2 }}
        >
          <Typography variant="heading2" gutterBottom align="center" sx={{ display: 'block'}}>
            Get Started
          </Typography>
          <Typography variant="normalText" gutterBottom align="center" sx={{ display: 'block',mb:2}}>
          Please provide some information about yourself so we can offer you the best possible care and services.
          </Typography>
          {/* <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="normalText2" align="center" sx={{ mt: 2, display: 'block' }}>
              Already have an account?
              <Link to="/" style={{ color: "#7B26C9", textDecoration: "none" }}>
                {" "}
                Log In
              </Link>
            </Typography>
          </Box> */}
          <Box component="form" noValidate autoComplete="off">
            {/* Login Details */}
            <hr
              style={{
                borderTop: "1px solid #CCCCCC",
                width: "100%",
                margin: "8px 0",
              }}
            />

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4} sx={{ mb: '3%'}}>
                <Typography variant="buttonText2" gutterBottom>
                  Login Details
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth margin="normal">
                  <FormLabel
                    htmlFor="phoneNumber"
                    sx={{ textAlign: "left" }}
                    required
                  >
                    <Typography variant="label">
                      Phone Number
                    </Typography>
                  </FormLabel>
                  <OutlinedInput
                    id="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    placeholder="Phone Number"
                    value={formData.phoneNumber}
                    onChange={handleNumericInputChange}
                    //   onChange={handleChange}
                    required
                    inputProps={{
                      inputMode: "numeric", // Ensure numeric keyboard on mobile
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth margin="normal">
                  <FormLabel htmlFor="email" sx={{ textAlign: "left" }}>
                    <Typography variant="label">
                    Email Address
                    </Typography>
                  </FormLabel>
                  <OutlinedInput
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email Address"
                    onChange={(e) => {
                      handleChange(e);
                      validateEmail(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}></Grid>
            </Grid>

            <hr
              style={{
                borderTop: "1px solid #CCCCCC",
                width: "100%",
                margin: "8px 0",
              }}
            />

            {/* Patient Details */}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4} sx={{ mb: '3%'}}>
                <Typography variant="buttonText2" gutterBottom>
                  Patient Details
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth margin="normal">
                  <FormLabel
                    htmlFor="firstName"
                    sx={{ textAlign: "left" }}
                    required
                  >
                    <Typography variant="label">
                    First Name
                    </Typography>
                  </FormLabel>
                  <OutlinedInput
                    id="firstName"
                    name="firstName"
                    type="text"
                    placeholder="First name"
                    onChange={handleChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth margin="normal">
                  <FormLabel
                    htmlFor="lastName"
                    sx={{ textAlign: "left" }}
                    required
                  >
                    <Typography variant="label">
                    Last Name
                    </Typography>
                  </FormLabel>
                  <OutlinedInput
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder="Last name"
                    onChange={handleChange}
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}></Grid>
              {/* <Grid item xs={12} md={4}></Grid> */}
              <Grid item xs={12} md={4}>
                <FormControl fullWidth margin="normal">
                  <FormLabel>
                    <Typography variant="label">
                    Gender
                    </Typography>
                    </FormLabel>
                    <Select
                      // labelId="gender-label"
                      id="gender"
                      name="gender"
                      required
                      onChange={handleChange}
                      defaultValue="" // Set default value to empty string
                      displayEmpty // This will make the placeholder visible
                      MenuProps={{
                        sx: {
                          "& .MuiPaper-root": {
                            backgroundColor: "#ffffff",
                          },
                          "& .MuiSelect-select": {
                            color: "grey",
                            backgroundColor: "#ffffff",
                            border: 'none',
                          },
                          "& .MuiMenuItem-root": {
                            "&.Mui-focused": {
                              backgroundColor: "transparent",
                              outline: "#ffffff",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>Select</MenuItem> {/* Placeholder option */}
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth margin="normal">
                  <FormLabel 
                  htmlFor="dateOfBirth"
                  sx={{ textAlign: "left" }}
                  required>
                    <Typography variant="label">
                    Date of Birth
                    </Typography>
                  </FormLabel>
                  <OutlinedInput
                  id="dateOfBirth"
                  name="dateOfBirth"
                  type="date"
                  required
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  style={{ color: "#5B6B7C", opacity: 1 }}
                  inputProps={{ max: today }}
                />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}></Grid>
              {/* <Grid item xs={12} md={4}></Grid> */}
              <Grid item xs={12} md={4}>
              <FormControl fullWidth margin="normal">
                  <FormLabel
                    htmlFor="aadhaarNumber"
                    sx={{ textAlign: "left" }}
                  >
                    <Typography variant="label">
                    Aadhaar Number
                    </Typography>
                  </FormLabel>
                  <OutlinedInput
                    id="aadhaarNumber"
                    name="aadhaarNumber"
                    type="text"
                    placeholder="Aadhaar Number"
                    value={formData.aadhaarNumber}
                    onChange={handleAadhaarNumberChange}
                    required
                    style= {{ borderColor: isAadhaarValid=== false ? '' : 'red' }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
              <FormControl fullWidth margin="normal">
                  <FormLabel
                    htmlFor="panNumber"
                    sx={{ textAlign: "left" }}
                  >
                    <Typography variant="label">
                    PAN Number
                    </Typography>
                  </FormLabel>
                  <OutlinedInput
                    id="panNumber"
                    name="panNumber"
                    type="text"
                    placeholder="PAN Number"
                    value={formData.panNumber}
                    onChange={handlePanCardChange}
                    style= {{ borderColor: isPanEdited=== false ? '' : 'red' }}
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>          

            <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={8}>
                    <hr
                      style={{
                        borderTop: "1px solid #CCCCCC",
                        width: "100%",
                        margin: "8px 0",
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={4}>
                  </Grid> */}
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="height"
                        sx={{ textAlign: "left" }}
                      >
                        <Typography variant="label">
                          Height (In feet and inches)
                        </Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="height"
                        name="height"
                        type="text"
                        placeholder="Height (In feet and inches)"
                        // value={userProfileData.emergency_contact ? userProfileData.emergency_contact : ''}
                        onChange={(e) => handleChange(e)}
                        value={formData.height}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="weight"
                        sx={{ textAlign: "left" }}
                      >
                        <Typography variant="label">Weight (In kgs)</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="weight"
                        name="weight"
                        type="text"
                        placeholder="Weight (In kgs)"
                        // value={userProfileData.emergency_contact ? userProfileData.emergency_contact : ''}
                        onChange={(e) => handleChange(e)}
                        value={formData.weight}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="bloodGroup"
                        sx={{ textAlign: "left" }}
                      >
                        <Typography variant="label">Blood Group</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="bloodGroup"
                        name="bloodGroup"
                        type="text"
                        placeholder="Blood Group"
                        // value={userProfileData.emergency_contact ? userProfileData.emergency_contact : ''}
                        onChange={(e) => handleChange(e)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}></Grid>
                </Grid>

            <hr
            style={{
              borderTop: "1px solid #CCCCCC",
              width: "100%",
              margin: "8px 0",
            }}
          />

            <Grid container spacing={2} alignItems="center">
              {/* <Grid item xs={12} md={4}></Grid> */}
              <Grid item xs={12} md={8}>
                <FormControl fullWidth margin="normal">
                  <FormLabel>
                    <Typography variant="label">
                    Consent to Text
                    </Typography>
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ "&.Mui-checked": { color: "#7B26C9" } }}
                      />
                    }
                    name="consentToText"
                    onChange={handleChange}
                    label={
                      <Typography variant="body1" sx={{ fontSize: 12, fontFamily: 'Inter' }}>
                        By checking the box, I agree to receive automated text
                        notifications.
                      </Typography>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          
          {/* Buttons and Text */}
          <Box
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="text2"
              align="right"
              sx={{ mt: 2, flex: "1", mr: 5 }}
            >
              Click{" "}
              <Link to="/" style={{ color: "#7B26C9", textDecoration: "none", fontWeight: 800,  }}>
              <Typography variant="link2">
              Log In
              </Typography>
              </Link>{" "}
              if you already have an account
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ p: 2 }}
              onClick={handleSubmit}
              disabled={!isFormValid() || !( formData.aadhaarNumber.length === 0 || formData.aadhaarNumber.length === 14 ) || !( formData.panNumber.length === 0 || formData.panNumber.length === 10 ) }
              size="large"
            >
              <Typography variant="buttonText" sx={{ fontFamily: 'Inter'}}>Submit</Typography>
            </Button>
          </Box>
        </Container>
        {loading && <LoadingSpinner />}
      </div>
    </ThemeProvider>
  );
};

export default SignUpForm;
