import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

// const pages = ["Products", "Pricing", "Blog"];
const settings = ["Profile", "Logout"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();

  const userData = Cookies.get("patientData")
    ? JSON.parse(Cookies.get("patientData"))
    : navigate("/");
  const handleMenuItemClick = (setting) => {
    if (setting === "Profile") {
      navigate("/profile");
    } else if (setting === "Logout") {
      // Remove the 'token' and 'userData' cookies
      Cookies.remove("patientToken");
      Cookies.remove("patientData");
      navigate("/");
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#ffffff", boxShadow: "none" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            // variant="h6"
            noWrap
            variant="h5"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontWeight: 600,
              fontFamily: "Inter"
              // textDecoration: "none",
            }}
          >
            <LocalHospitalIcon
              sx={{ display: { xs: "none", md: "flex" }, mr: 1, color:'#7B26C9' }}
            />
           <Link to={'/home'} style={{ textDecoration: 'none', color: '#7B26C9'}}> Patient Portal</Link>
          </Typography>

          <Typography
            variant="customFont2"
            noWrap
            component="a"
            to="/home"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              //   fontFamily: 'monospace',
              fontWeight: 700,
              // letterSpacing: ".3rem",
              // color: "inherit",
              textDecoration: "none",
              fontFamily: 'Inter'
            }}
          >
            <LocalHospitalIcon
              sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
            />
             <Link to={'/home'} style={{ textDecoration: 'none', color: '#7B26C9'}}> Patient Portal</Link>
          </Typography>

          <Box sx={{ flexGrow: 0 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: "50%",
                right: 0,
                transform: "translateY(-50%)",
              }}
            >
              <Typography variant="body1" sx={{ mr: 2, fontFamily: "Inter", cursor: 'pointer' }} onClick={handleOpenUserMenu}>
                <Tooltip title="Open settings">
                {userData
                  ? `${userData.first_name}` + " " + `${userData.last_name}`
                  : ""}
                   </Tooltip>
              </Typography>
              <Typography variant="customFont2">
                <Tooltip title="Open settings">
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{ p: 0, color: "#7B26C9" }}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Box>

            <Menu
              sx={{
                mt: "45px",
                "& .MuiPaper-root": {
                  backgroundColor: "#ffffff",
                  minWidth: "10em",
                },
                fontFamily: 'Inter'
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleMenuItemClick(setting)}
                >
                  <Typography textAlign="center" sx={{ fontFamily: 'Inter'}}>{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
