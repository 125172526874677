import React, { useEffect } from 'react';
import LoginForm from './components/SignIn'; 
import SignUpForm from './components/SigUp'; 
import OtpSignIn from './components/OtpSignIn';
import PateintHome from './components/PateintHome';
import PateintProfile from './components/PateintProfile';
import { SnackbarProvider } from './components/SnackBar';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

import './App.css';

if (process.env.REACT_APP_NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
}

const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }
  }, [location]);

  return null;
};

const App = () => {
  return (
    <Router>
      <SnackbarProvider>
        <RouteTracker />
        <Routes>
          <Route path="/signup" element={<SignUpForm />} />
          <Route path="/" element={<LoginForm />} />
          <Route path="/signin" element={<OtpSignIn />} />
          <Route path="/home" element={<PateintHome />} />
          <Route path="/profile" element={<PateintProfile />} />
        </Routes>
      </SnackbarProvider>
    </Router>
  );
};

export default App;
