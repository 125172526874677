import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  Typography,
  Box,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getCheckoutTheme from "./getCheckoutTheme";
import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PurplegradientIcon from "./Purplegradient/PurplegradientIcon";
import axios from "axios";
import AppBar from "./AppBar";
import PateintDocument from "./PatientDocument";

import Cookies from "js-cookie";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const GreyBackgroundBox = styled(Box)({
  backgroundColor: "#edeff3", // Adjust the color as needed
  minHeight: "100vh", // Ensure the box takes up the entire viewport height
   minWidth: '100vw'
});

const PateintHome = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("Home Component mounted");
  }, []);

  const checkoutTheme = createTheme(getCheckoutTheme("light"));
  const cookieData = {
    token: Cookies.get("patientToken") ? Cookies.get("patientToken") : navigate("/"),
    userData: Cookies.get("patientData")
      ? JSON.parse(Cookies.get("patientData"))
      : navigate("/"),
  };
  return (
    <ThemeProvider theme={checkoutTheme}>
      <GreyBackgroundBox>
        <AppBar userData={cookieData.userData} />
        <Container>
          <Box sx={{ mt: 4 }}>
            <Typography variant="customFont" gutterBottom sx={{ fontFamily: 'Inter', fontWeight: 600}}>
              Welcome,{" "}
              {cookieData.userData
                ? `${cookieData.userData.first_name}` +
                  " " +
                  `${cookieData.userData.last_name}!`
                : ""}
            </Typography>
          </Box>
        </Container>
        <Container sx={{ backgroundColor: "#ffffff" }}>
          <Box sx={{ mt: 4 }}>
            <PateintDocument token={cookieData.token} />
          </Box>
        </Container>
      </GreyBackgroundBox>
    </ThemeProvider>
  );
};

export default PateintHome;
