import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  Typography,
  Box,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getCheckoutTheme from "./getCheckoutTheme";
import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PurplegradientIcon from "./Purplegradient/PurplegradientIcon";
import axios from "axios";
import { useSnackbar } from './SnackBar';
import LoadingSpinner from './LoadingSpinner'; 

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const LoginForm = () => {
  const checkoutTheme = createTheme(getCheckoutTheme("light"));
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState(true);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [validationMessage, setValidationMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleNumericInputChange = (event) => {
    const value = event.target.value.replace(/\D/, "").slice(0, 10); // Remove non-numeric characters
    setMobileNumber(value);
    if (value.length === 10) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENVIRONMENT}/api/auth/send_otp`,
        {
          phone: mobileNumber,
        }
      );
      showSnackbar(response.data.message, 'success');
      navigate("/signin", {
        state: {
          responseData: {
            opt: response.data.otp,
            mobile: mobileNumber,
          },
        },
      });
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          // showSnackbar(error.response.data.message, 'error');
          setValidationMessage(error.response.data.message)
        }
      } else {
        showSnackbar(error.message, 'error');
        // showSnackbar(error.response.data.message, 'error');
      }
    } 
    setLoading(false)
  };

  return (
    <ThemeProvider theme={checkoutTheme}>
      <div
        style={{
          width: "100%",
          height: "100vh",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
          overflow: "hidden",
        }}
      >
        <PurplegradientIcon style={{ width: "100%", minHeight: "100vh" }} />
      </div>
      <Container
        maxWidth="xs"
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem 3rem 1rem 4rem",
            boxShadow: "4px 2px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "1rem",
            background: "linear-gradient(to right, #FFFFFF, #d1e8ff)",
            width: "100%",
          }}
        >
          <Typography
            variant="heading"
            // component="h4"
            gutterBottom
            sx={{ textAlign: "left", marginBottom: "2em" }}
          >
            Patient Portal
          </Typography>
          <Typography
            variant="subheading"
            // component="h2"
            // gutterBottom
            sx={{ textAlign: "left" }}
          >
            Log In
          </Typography>
          {/* <Typography variant="text" gutterBottom sx={{ textAlign: "left" }}>
            Please enter your mobile number.
          </Typography> */}
          <Grid container spacing={3} marginTop={2}>
            <FormGrid item xs={12}>
              <FormLabel htmlFor="mobile" sx={{ textAlign: "left" }} required >
                <Typography variant="label" sx={{ fontFamily: 'Inter'}}>
                  Mobile Number
                </Typography>
              </FormLabel>
              <OutlinedInput
                id="mobile"
                name="mobile"
                type="text"
                placeholder="Enter your mobile number"
                required
                value={mobileNumber}
                onChange={handleNumericInputChange}
                error={error === "" && mobileNumber.length !== 10}
                endAdornment={
                  <InputAdornment position="end">
                    {mobileNumber.length === 10 && (
                      <CheckCircleOutlineIcon sx={{ color: "green" }} />
                    )}
                  </InputAdornment>
                }
                inputProps={{
                  inputMode: "numeric", // This will show numeric keyboard on mobile devices
                }}
              />
            </FormGrid>
            <Grid item xs={12} sx={validationMessage ? { mt: 0, pt: '10px !important' } : { mt: 0 }}>
              {validationMessage && (
                <Typography variant="validationMessage" sx={{ color: 'red',mt: 0, pt:1 }}>
                  {validationMessage}
                </Typography>
              )}
              <Button
                variant="contained"
                fullWidth
                onClick={handleLogin}
                style={{ borderRadius: "25vw", marginTop: 25 }}
                disabled={error}
                size="medium"
              >
                <Typography variant="buttonText">Log In</Typography>
              </Button>
            </Grid>
          </Grid>
          <Typography
            variant="text"
            gutterBottom
            sx={{ textAlign: "center", marginTop: "3em", fontFamily: 'Inter' }}
          >
            Don't have an account?{" "}
            <Link
              to="/signup"
              style={{ color: "#43349C", textDecoration: "none", }}
            >
              <Typography variant="link" sx={{ fontFamily: 'Inter'}}>
              Register here.
              </Typography>
            </Link>
          </Typography>
        </Box>
      </Container>
      {loading && <LoadingSpinner />}
    </ThemeProvider>
  );
};

export default LoginForm;
